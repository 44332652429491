// BUTTONS

.el-button {
	letter-spacing: 0.7px;
}

.el-button--primary:not(.is-plain),
.el-button--primary:not(.is-plain):hover,
.el-button--primary:not(.is-plain):focus,
.el-button--primary:not(.is-plain):active {
	background-color: $blue;
	border-color: $blue;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
	color: #FFF;
	background-color: $blue-faded;
	border-color: $blue-faded;
}

.el-button--success:not(.is-plain),
.el-button--success:not(.is-plain):hover,
.el-button--success:not(.is-plain):focus,
.el-button--success:not(.is-plain):active {
	background-color: $success-green;
	border-color: $success-green;
}

.el-button,
.el-button:focus {
	box-shadow: 1px 1px 2px 0px $shadow-gray;
}

.el-button:active {
	box-shadow: inset 1px 1px 2px 0px $shadow-gray;
}

.el-button:hover,
.el-button:active {
	opacity: 0.9;
}

// LINKS

.el-link {
	font-size: inherit;
	font-weight: 400;
}

// FORMS

.el-form-item__label,
.el-checkbox__label,
.el-checkbox__input.is-checked+.el-checkbox__label {
	color: #6d6c6b;
	font-size: 13px;
}

.el-form--label-top .el-form-item__label {
	line-height: 1em;
	padding: 0 0 7px 1px;
}

.el-input__inner {
	border: 1px solid #dcdee2;
}

.el-form-item.is-success .el-input__inner,
.el-form-item.is-success .el-input__inner:focus,
.el-form-item.is-success .el-textarea__inner,
.el-form-item.is-success .el-textarea__inner:focus {
	border-color: $success-green;
}

// RADIO BUTTONS

.el-radio-group {
	margin-bottom: 20px;

	.el-radio {
		margin-right: 0;

		&.is-checked {
			border-color: $blue;
		}

		.el-radio__input.is-checked .el-radio__inner {
			border-color: $blue;
			background: $blue;
		}

		.el-radio__inner:hover {
			border-color: #DCDFE6;
		}

		.el-radio__input.is-checked+.el-radio__label {
			color: $blue;
		}

		&.is-bordered+.el-radio.is-bordered {
			margin-left: 15px;
		}
	}
}

// CHECKBOXES

.el-checkbox-button__inner,
.el-radio,
.el-checkbox {
	font-weight: 400;
}

.el-checkbox__inner {
	border-color: #dcdee2;
}

.el-checkbox__inner:hover,
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-focus .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	border-color: $blue;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: $blue;
}

.el-checkbox,
.el-checkbox-button__inner {
	white-space: normal;
}

.el-checkbox:not(.is-bordered) .el-checkbox__input {
	position: absolute;
	top: 3px;
}

.el-checkbox:not(.is-bordered) .el-checkbox__label {
	padding-left: 22px;
}

.el-checkbox.is-bordered.el-checkbox--small {
    padding: 7px 15px 3px 10px;
	margin-right: 15px;
}

.el-checkbox.is-bordered + .el-checkbox.is-bordered {
	margin: 0;
}

// SELECTS

.el-select {
	display: block;
}

// BANNERS

.el-alert {
	margin-bottom: 25px;
}

.el-alert--success.is-light {
	background-color: #e3f5d9;
	color: #096b31;

	.el-alert__closebtn {
		color: #096b31;
	}
}

.el-alert--info.is-light {
	background-color: #efeff1;
	color: #646669;

	.el-alert__closebtn {
		color: #646669;
	}
}

.el-alert--warning.is-light {
	background-color: #fff1de;
	color: #a26a14;

	.el-alert__closebtn {
		color: #a26a14;
	}
}

.el-alert--error.is-light {
	background-color: #ffdfd6;
	color: #9e5252;

	.el-alert__closebtn {
		color: #9e5252;
	}
}

// TABLES

.el-table .cell {
	word-break: break-word;
	font-size: 12px;
}