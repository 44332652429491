body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 200;
	font-size: 13px;
	letter-spacing: 0.25px;
	word-break: break-word;
}

p {
	margin: 0 0 10px;
}

h1 {
	font-weight: 400;
	font-size: 18px;
}

h2 {
	font-weight: 400;
	font-size: 15px;
}

small,
.small-text {
	font-size: 12px;
}

.large-text {
	font-size: 15px;
}

b,
strong,
.bold-text {
	font-weight: bold;
}

.blue-text {
	color: #155e82;
}

.red-text {
	color: #ff0000;
}

@media screen and (max-width: 620px) {
	body {
		font-size: 13px;
	}

	h1 {
		font-size: 16px;
	}

	h2,
	.large-text {
		font-size: 14px;
	}

	// small,
	// .small-text {
	// 	font-size: 11px;
	// }
}
