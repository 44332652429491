.view-wrapper {
	font-family: sans-serif;
	min-height: 100vh;
	padding: 0;
	color: white;

	.el-link.el-link--primary {
		color: $blue;
		justify-content: left;
		margin: 18px 0;
		width: 100%;
		padding: 10px 0;

		@media screen and (max-width: 991px) {
			margin-top: 0;
		}
	}

	.el-link.el-link--primary.is-disabled {
		color: #b9b9b9;
	}

	.el-form-item--small .el-form-item__error {
		padding-top: 3px;
	}
}

.form-wrapper {
	position: relative;
	z-index: 2;
	width: calc(100vw - 30px);
	max-width: 800px;
	height: auto;
	padding: 25px 40px;
	margin: auto;
	margin-top: 35px;
	background: #fff;
	color: #464646;
	border-radius: 5px;
	box-shadow: 1px 1px 3px 0px $shadow-gray;

	@media screen and (max-width: 767px) {
		margin-top: 25px;
		padding: 15px 25px;
	}

	.el-select-dropdown.el-popper {
		left: 0 !important;
	}

	.error-text {
		color: #ff0000;
		font-size: 12px;
		line-height: 1;
	}

	.info-icon {
		color: $blue;
		display: inline-block;
		border: 1px solid $blue;
		border-radius: 0.5em;
		font-size: 12px;
		height: 12px;
		width: 12px;
		line-height: 12px;
		margin: 0 0 0 4px;
		position: relative;
		top: -1px;
		text-align: center;
		cursor: pointer;
	}
}

.payment-button {
	padding: 0;
	width: 100%;
	height: 32px;
}
